import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { useUser } from 'Core/Hooks/useUser'
import { enqueueNotification } from 'Utils/Helpers'
import { AccessGraph, GraphResourceNodeActionsFunctionMap } from 'V2Components'
import {
  getAWSResourcePolicyAppRole,
  getK8NamespaceRsrcsFromPrincipal,
  getKubeType,
  getPolicyAttachedIAMActions,
  getPolicyError,
  getPolicyIssuedToEntities,
  getPolicyResourcesRefs,
  isPolicyIssuedToUser,
  isWorkloadIdentityPolicy
} from 'features/policy'
import { createRsrcKey, getResourceName } from 'features/resources'
import {
  PRINCIPAL_OBJECT_KINDS,
  getRDPServerAccessLinksMap,
  getTargetsPolicyRules
} from 'features/targets'
import _ from 'lodash'
import React, { useEffect } from 'react'

function PolicyAccessGraphView({ policy }) {
  const { user, isAgentlessMode } = useUser()

  const { getObjectRef, slices, selectDispatch } = useMultiSlice([
    'userList',
    'groupList',
    'serviceAccounts',
    'awsResources',
    'gcpResources',
    'azureResources',
    'appRolesList',
    'serverList',
    'databases',
    'kubeNamespaces',
    'applicationList',
    'iamActions',
    'githubResources',
    'githubAccount',
    'workloads',
    'rdpServers',
    'kubeClusters',
    'kafkas',
    'salesForceUsersList',
    'salesForcePermissionList',
    'snowFlakeAccounts',
    'snowFlakeResources',
    'snowFlakeRoles'
    // 'dataBricksAccounts',
    // 'dataBricksResources'
  ])

  useEffect(() => {
    selectDispatch([
      'applicationList',
      'githubAccount',
      'kubeClusters',
      'salesForceUsersList',
      'salesForcePermissionList',
      'tenantprofiles',
      'snowFlakeAccounts',
      'snowFlakeResources',
      'snowFlakeRoles'
      // 'dataBricksResources',
      // 'dataBricksAccounts'
    ])
  }, [])

  const getPrincipalOnResources = () => {
    const map = {}
    const actionKeys = Object.keys(policy.Spec.ActionMap)
    actionKeys.forEach((actionKey) => {
      const rules = policy.Spec.ActionMap[actionKey].PolicyRule
      rules.forEach((rule) => {
        if (PRINCIPAL_OBJECT_KINDS.includes(rule.ObjectRef.RefKind)) {
          map[createRsrcKey(rule.ObjectRef)] = rule.Principal
        }
      })
    })

    return map
  }

  const getEntities = () => {
    const refs = getPolicyIssuedToEntities(policy)
    // check if user is belong to salesforce
    const salesforceUserObj = _.find(slices.salesForceUsersList, {
      ObjectMeta: { ID: refs[0]?.RefID }
    })

    const filterSFUSerObj = _.find(slices.userList, {
      Spec: { EmailID: salesforceUserObj?.UserName }
    })

    if (filterSFUSerObj) {
      const userRefs = [
        {
          RefKind: filterSFUSerObj.ObjectMeta.Kind,
          RefID: filterSFUSerObj.ObjectMeta.ID
        }
      ]
      return getObjectRef(userRefs)
    }
    return getObjectRef(refs)
  }

  const isPolicyKubenamespaceRsrcs = (p) => {
    const { KubeAccess } = getTargetsPolicyRules(policy)
    const isK8RsrcPolicy = KubeAccess.some((rule) => {
      return rule.ObjectRef.RefKind === 'KubeNamespace' && rule.Principal
    })
    return isK8RsrcPolicy
  }

  const getK8commond = (rsrc, cluster) => {
    if (isPolicyIssuedToUser(policy, user)) {
      const actions = []
      actions.push({
        id: createRsrcKey(rsrc),
        label: `Copy Context Name - ${getResourceName(policy)}`,
        onClick: () => {
          navigator.clipboard.writeText(`${cluster.Spec.IAMRes}:procyon:` + policy?.ObjectMeta.Name)
          enqueueNotification(`Context Name  copied : ${cluster.Spec.IAMRes}`, 'info')
        }
      })
      return actions
    }
  }

  const getCustomResources = () => {
    // Verify if the policy is kubeNamespace rsrcs
    if (isPolicyKubenamespaceRsrcs(policy)) {
      const customRsrcs = []
      const { KubeAccess } = getTargetsPolicyRules(policy)

      // Iterate over each rule in KubeAccess
      KubeAccess.forEach((rule, index) => {
        const { Principal } = rule
        const rsrcs = getK8NamespaceRsrcsFromPrincipal(Principal)
        const refs = getObjectRef(rule.ObjectRef)
        const cluster = getObjectRef(refs?.Spec?.Cluster)
        // Generate custom resources for each rule
        rsrcs.forEach(({ type, name }) => {
          const uniqueId = `${type}_${name}_${index}`
          const actionFn = getK8commond(refs, cluster)
          customRsrcs.push({
            id: uniqueId,
            actions: actionFn,
            description: getResourceName(refs),
            label: `${type}:${name}`,
            type: getKubeType(type)
          })
        })
      })

      return customRsrcs
    }
  }

  const getResources = () => {
    const { KubeAccess } = getTargetsPolicyRules(policy)
    // Holds the keys of kns with rsrcs like pods
    const k8RrscNamespaceKeys = KubeAccess.map((r) => {
      if (r.Principal && r.ObjectRef.RefKind === 'KubeNamespace') return createRsrcKey(r.ObjectRef)
    })

    const refs = getPolicyResourcesRefs(policy)
    // Here we remocve the namespace which has pods and resources.
    const filteredRefs = refs.filter((e) => !k8RrscNamespaceKeys.includes(createRsrcKey(e)))

    return getObjectRef(filteredRefs)
  }

  const getRsrcToRolesObject = () => {
    const obj = {}
    if (policy.Type !== 'IAMAction') return {}
    const rsrcAttachedIAMActions = getPolicyAttachedIAMActions(policy)
    for (const rsrcKey in rsrcAttachedIAMActions) {
      const actionRefs = rsrcAttachedIAMActions[rsrcKey]
      obj[rsrcKey] = getObjectRef(actionRefs)
    }

    return obj
  }

  const getGithubApplicationSaml = (refID) => {
    const githubAccountId = _.find(slices?.githubAccount, { ObjectMeta: { ID: refID } })
    const githubApplicationIdWithSSOUrl = _.find(slices?.applicationList, {
      ObjectMeta: { ID: githubAccountId?.Application?.RefID }
    })
    return githubApplicationIdWithSSOUrl
  }

  const createNodesActionsObject = () => {
    const actionsObj = {}
    const rsrcs = getResources()
    rsrcs.forEach((r) => {
      const Kind = r.ObjectMeta.Kind
      const key = createRsrcKey(r)
      const actionsFn = GraphResourceNodeActionsFunctionMap[Kind]
      if (!actionsFn) return
      if (!actionsObj[key]) {
        // approle Only for AwsResource kinds
        const appRoleRef = getAWSResourcePolicyAppRole(policy)
        const approle = appRoleRef && getObjectRef(appRoleRef)
        const errorInPolicy = getPolicyError(policy)
        // application SSO link for github
        const appSSOLinkApp = getGithubApplicationSaml(r?.Spec?.Account?.RefID || r?.ObjectMeta?.ID)

        // rdpserver signin url
        const principal = getPrincipalOnResources()[createRsrcKey(r)]

        actionsObj[key] = actionsFn({
          rsrc: r,
          parentRsrc: getObjectRef(r?.Spec?.Parent),
          // Hide signin for workload id policys, but the sign in for kube is shown because that sign in opens a command modal
          hideSignIn: r.ObjectMeta.Kind !== 'KubeNamespace' && isWorkloadIdentityPolicy(policy),
          isIssuedToLoggedInUser: isPolicyIssuedToUser(policy, user),
          approle,
          errorInPolicy,
          appSSOLinkApp,
          rdpSingInUrl: {
            [principal]: getRDPServerAccessLinksMap(r)[principal] || ''
          },
          kubeCluster: getObjectRef(r?.Spec?.Cluster),
          policy,
          user,
          isAgentlessMode
        })
      }
    })
    return actionsObj
  }
  console.log('[pac](rsrc):', getResources())
  return (
    <div>
      <AccessGraph
        customResources={getCustomResources()}
        //@ts-ignore
        resourceToRolesMapObject={getRsrcToRolesObject()}
        entities={getEntities()}
        policy={policy}
        resources={getResources()}
        actionsObj={createNodesActionsObject()}
      />
    </div>
  )
}

export { PolicyAccessGraphView }
