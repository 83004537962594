import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons'
import useAppView from 'Core/Hooks/useAppView'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { useUser } from 'Core/Hooks/useUser'
import { openURLSafely } from 'Utils/PureHelperFuctions'
import { FullScreenContentModal, PaginationView } from 'V2Components'
import {
  CreateApplicationPolicyFlow,
  CreateApplicationRequestFlow,
  DeleteApplicationModal,
  getApplicationSignInURL,
  getApplicationType,
  isReadOnlyApp,
  useApplicationAccess
} from 'features/applications'
import { getIAMResourceDisplayType } from 'features/iamResources'
import {
  AccessCartProvider,
  createRef,
  createRsrcKey,
  getResourceName,
  getResourcePolicyTags,
  useAccessCartProvider
} from 'features/resources'
import { ApplicationCard, TargetIcon } from 'procyon-ui'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Header } from './Components'

import { AddApplication } from '../../../../AddApplication'
import { CheckboxFilter } from './Components/CheckboxFilter'
const appTypes = ['snowflake', 'databricks']
const USER_CART_KEY = 'userApplications'
const ADMIN_CART_KEY = 'adminApplications'

function ApplicationsList() {
  const { rdpurl, isAgentlessMode } = useUser()
  const [searchKey, setSearchKey] = useState('')
  const [selectedTag, setSelectedTag] = useState('')
  const [showEditAppModal, setShowEditAppModal] = useState(false)
  const [showDeleteAppModal, setShowDeleteAppModal] = useState(false)
  const [showAddApplication, setShowAddApplication] = useState(false)
  const { getObjectRef: getRsrcsRef } = useMultiSlice([
    'serviceAccounts',
    'githubResources',
    'applicationList'
  ])
  const [activeCheckboxFilters, setActiveCheckboxFilters] = useState([])
  const [selectedApplication, setSelectedApplication] = useState(null)
  const [views, setViews] = useState({
    showEntitySlectionModal: false,
    accessRequestSubmissionModal: false
  })
  const history = useHistory()
  const { slices, selectDispatch } = useMultiSlice([
    'applicationList',
    'accountList',
    'salesForceAccountList',
    'salesForcePermissionList',
    'salesForceUsersList'
  ])
  const { getApplicationRsrcsAccessMap } = useApplicationAccess()
  const [nonHttp, setNonHttp] = useState(['Dropbox', 'github'])
  const [isNonHttpExistInCart, setIsNonHttpExistInCart] = useState(false)

  const { appView } = useAppView()
  const accessObject = getApplicationRsrcsAccessMap()

  const { addItemsToCart, isResourceInCart, isCartEmpty, cartItems } = useAccessCartProvider()

  const handleViewsChange = (v) => {
    setViews((s) => ({ ...s, ...v }))
  }

  const getTagsDropdownOptions = () => {
    const options = new Set()

    slices.applicationList.forEach((s) => {
      const tags = getResourcePolicyTags(s)
      if (tags.hasOwnProperty('appgrp')) {
        const { appgrp, ...rest } = tags
        // Split the comma-separated values
        const values = appgrp.split(',')
        // Add unique values to the set
        values.forEach((value) => {
          options.add(value.trim())
        })
      }
    })

    return [...options].map((value) => ({ label: value, value: value }))
  }

  const handleAccessClick = (app) => {
    // if app kind is salesforce then direct redirect to permission tab
    if (app.Spec.AppType === 'salesforce') {
      return history.push(
        `/${appView}/applications/${encodeURIComponent(app.ObjectMeta.Name)}/${encodeURIComponent(
          'permissions'
        )}`
      )
    }

    const existingCartItemsappType = []
    if (!isCartEmpty) {
      cartItems.forEach(({ Resource }) => {
        let rsrc = null
        rsrc = getRsrcsRef(Resource)
        if (!rsrc) return
        const appType = getIAMResourceDisplayType(rsrc)
        existingCartItemsappType.push(appType)
      })

      if (!existingCartItemsappType.some((type) => nonHttp.includes(type))) {
        if (!isResourceInCart(createRef(app)) && app.Spec.AppType === 'http') {
          return addItemsToCart({
            resourceRef: createRef(app),
            principal: '',
            roles: []
          })
        }
      }
    } else {
      return addItemsToCart({
        resourceRef: createRef(app),
        principal: '',
        roles: []
      })
    }

    // if (appView === 'admin') {
    //   handleViewsChange({
    //     showEntitySlectionModal: false
    //   })
    // } else {
    //   handleViewsChange({
    //     accessRequestSubmissionModal: true
    //   })
    // }
  }

  const getAppAccessState = (app) => {
    let accessState = 'access'
    if (accessObject[createRsrcKey(app)] || appView === 'admin') accessState = 'signIn'
    //@ts-ignore
    if (isResourceInCart(createRef(app))) accessState = 'requested'

    // sign for salesforce
    if (app.Spec.AppType === 'salesforce' && appView === 'user') {
      const crmEntityRef = accessObject && Object.keys(accessObject)[0]?.split('+')
      if (crmEntityRef) {
        const getCrmObject = slices?.salesForcePermissionList?.find(
          (crm) => crm.ObjectMeta.ID === crmEntityRef[1]
        )
        const filterSfAccount = slices?.salesForceAccountList?.find(
          (sfaccount) => sfaccount.ObjectMeta.ID === getCrmObject?.SalesforceAccount?.RefID
        )
        const sfApp = slices?.applicationList?.find(
          (apps) => apps.ObjectMeta.ID === filterSfAccount?.Application?.RefID
        )
        if (createRsrcKey(sfApp) === createRsrcKey(app)) {
          accessState = 'signIn'
        }
      }
    }

    return accessState
  }

  const getDotMenuItems = ({ app, isAppGranted }) => {
    const items = []
    const existingCartItemsappType = []
    if (isCartEmpty && app.Spec.AppType !== 'http') {
      if (appView === 'admin' && app.Spec.AppType !== 'github') {
        items.push({
          title: 'Create Policy',
          action: () => handleAccessClick(app)
        })
      }

      if (appView === 'user' && !isAppGranted) {
        items.push({
          title: 'Create Request',
          action: () => handleAccessClick(app)
        })
      }
    } else {
      cartItems.forEach(({ Resource }) => {
        let rsrc = null
        rsrc = getRsrcsRef(Resource)
        if (!rsrc) return
        const appType = getIAMResourceDisplayType(rsrc)
        existingCartItemsappType.push(appType)
      })
    }

    if (!existingCartItemsappType.some((type) => nonHttp.includes(type))) {
      if (!isResourceInCart(createRef(app)) && appView === 'admin' && app.Spec.AppType === 'http') {
        items.push({
          title: 'Create Policy',
          action: () => handleAccessClick(app)
        })
      }
    } else {
      setIsNonHttpExistInCart(true)
    }

    if (appView == 'admin') {
      items.push(
        {
          title: 'Edit',
          action: () => {
            setSelectedApplication(app)
            setShowEditAppModal(true)
          }
        },
        {
          title: <span className='text-red-600'>Delete</span>,
          action: () => {
            setSelectedApplication(app)
            setShowDeleteAppModal(true)
          }
        }
      )
    }

    const isCreatePolicyExist = items.some(
      (item) => item.title === 'Create Policy' && appView === 'admin'
    )

    if (!isCreatePolicyExist && app.Spec.AppType !== 'github') {
      items.push({
        title: 'Sign In',
        action: () => {
          getSingInUrlWithAgentlessCheck(app)
        }
      })
    }

    return items
  }

  const isSearchMatch = (app) => {
    if (searchKey) {
      const data = `${getResourceName(app)}${getApplicationType(app)}`
      if (data.toLowerCase().includes(searchKey)) return true
      else return false
    }
    return true
  }

  // handle github
  const getIsReadOnly = (app) => {
    if (app.Spec.AppType === 'github' && appView === 'user') {
      return true
    } else {
      return isReadOnlyApp(app)
    }
  }

  const getFilteredApps = () => {
    let filteredList = slices.applicationList.filter((app) => !appTypes.includes(app.Spec.AppType))

    if (activeCheckboxFilters.length !== 0) {
      let matchedFilter = filteredList.filter((app) =>
        activeCheckboxFilters.includes(app.Spec.AppType)
      )
      return matchedFilter.filter(isSearchMatch)
    }

    return slices.applicationList.filter((app) => {
      if (!isSearchMatch(app)) return false
      // Filter by tag
      if (selectedTag) {
        const { appgrp } = getResourcePolicyTags(app)
        const spliteTags = appgrp?.split(',')
        const tagMatch = spliteTags?.some((tag) => tag.trim() === selectedTag.trim())
        if (tagMatch === '-') return
        if (!tagMatch) return
      }
      return true
    })
  }

  const renderApplicationCardFunction = (app) => {
    const serviceType = getApplicationType(app)
    const data = {
      name: getResourceName(app),
      accessState: getAppAccessState(app),
      serviceType,
      resourceType: serviceType,
      key: createRsrcKey(app),
      isReadOnly: getIsReadOnly(app),
      dotMenuItems: getDotMenuItems({ app, isAppGranted: getAppAccessState(app) === 'signIn' }),
      onAccessClick: () => handleAccessClick(app),
      app
    }

    //@ts-ignore
    return (
      <ApplicationCard
        onClickSignIn={() => getSingInUrlWithAgentlessCheck(data.app)}
        onClickMoreInfo={() => handleMoreInfoClick(data.app)}
        onClickPolicy={data.onAccessClick}
        onClickRequest={data.onAccessClick}
        forPolicy={appView === 'admin'}
        isReadOnly={data.isReadOnly}
        width='100%'
        key={data.key}
        //@ts-ignore
        accessState={data.accessState}
        dotMenuItems={
          data.dotMenuItems.length
            ? [
                {
                  icon: faEllipsisVertical,
                  submenu: data.dotMenuItems,
                  title: ''
                }
              ]
            : []
        }
        resourceName={data.name}
        resourceType={data.resourceType}
        //@ts-ignore
        serviceType={ServiceTypeIconMap[data.serviceType]}
        customIcon={data.serviceType === 'SALESFORCE' && <TargetIcon type='SALESFORCE' />}
      />
    )
  }

  const handleMoreInfoClick = (app) =>
    history.push(`/${appView}/applications/${encodeURIComponent(app.ObjectMeta.Name)}`)

  useEffect(() => {
    selectDispatch([
      'applicationList',
      'accountList',
      'policyList',
      'userList',
      'groupList',
      'medusaNodes',
      'salesForceAccountList',
      'salesForcePermissionList'
    ])
  }, [])

  const getSingInUrlWithAgentlessCheck = (app) => {
    let url = getApplicationSignInURL(app)

    if (isAgentlessMode) {
      url = url.replace('.default', '-app-default').replace('.procyon.ai', `.${rdpurl}`)
      url && openURLSafely(url)
    } else {
      url && openURLSafely(url)
    }
  }

  return (
    <div className='flex justify-between h-[100vh]'>
      <div className='w-[20%] bg-[#f9fbfc] pl-5'>
        <CheckboxFilter
          activeFilters={activeCheckboxFilters}
          onActiveFilterChange={setActiveCheckboxFilters}
        />
      </div>
      <div className='w-[73%]'>
        <Header
          searchKey={searchKey}
          setSearchKey={setSearchKey}
          tags={getTagsDropdownOptions()}
          selectedTag={selectedTag}
          setSelectedTag={setSelectedTag}
        />

        <PaginationView
          itemsCount={40}
          data={getFilteredApps()}
          renderFunction={renderApplicationCardFunction}
          WrapperComponent={({ children }) => (
            <div className='grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 grid gap-8 mt-4'>
              {children}
            </div>
          )}
        />
      </div>

      {appView === 'admin' && (
        <CreateApplicationPolicyFlow views={views} setViews={handleViewsChange} />
      )}
      {appView === 'user' && <CreateApplicationRequestFlow setViews={setViews} views={views} />}
      {showDeleteAppModal && (
        <DeleteApplicationModal
          cleanUpFn={() => {
            setSelectedApplication(null)
            setShowDeleteAppModal(false)
          }}
          app={selectedApplication}
        />
      )}

      {showAddApplication && (
        <FullScreenContentModal>
          <AddApplication
            type=''
            onComplete={() => setShowAddApplication(false)}
            onClose={() => setShowAddApplication(false)}
          />
        </FullScreenContentModal>
      )}

      {showEditAppModal && (
        <FullScreenContentModal>
          <AddApplication
            type=''
            onComplete={() => setShowEditAppModal(false)}
            app={selectedApplication}
            onClose={() => setShowEditAppModal(false)}
          />
        </FullScreenContentModal>
      )}
    </div>
  )
}

const WrappedApplicationsList = () => {
  const { appView } = useAppView()
  const cartKey = appView === 'admin' ? ADMIN_CART_KEY : USER_CART_KEY

  return (
    <AccessCartProvider cartKey={cartKey}>
      <ApplicationsList />
    </AccessCartProvider>
  )
}

const ServiceTypeIconMap = {
  AWS: 'aws',
  AZURE: 'azure',
  GCP: 'gcp',
  HTTP: 'apiRest',
  DROPBOX: 'dropbox',
  GITHUB: 'github',
  SALESFORCE: 'salesforce'
}

export { WrappedApplicationsList as ApplicationsList }
